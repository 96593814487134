import React from 'react';
import { ServerURLs } from '../data/serverUrls';

export type AppConfig = {
    urls: ServerURLs;
    loginId: string;
    locale: string;
    brand: string;
};

const AppConfigContext = React.createContext<AppConfig | undefined>(void 0);

type AppConfigProviderProps = AppConfig & {
    children: React.ReactNode;
};

export function AppConfigProvider(props: AppConfigProviderProps) {
    const { children, ...config } = props;
    const value = React.useMemo<AppConfig>(() => config, [config]);
    return (
        <AppConfigContext.Provider value={value}>
            {children}
        </AppConfigContext.Provider>
    );
}

export function useAppConfig() {
    const context = React.useContext(AppConfigContext);
    if (typeof context === 'undefined') {
        throw new Error(
            'useAppConfig must be used within an AppConfigProvider',
        );
    }
    return context;
}
