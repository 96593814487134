import { i18n } from '@lingui/core';
import { Item } from '../data/recommendationDataTypes';

export function getTitleForAds(
    adId: number,
    initialData: any,
    elementsLength: number,
): string {
    if (elementsLength > 0) {
        if (adId) {
            return i18n.t({
                id: 'ads.title.mlt',
                message: 'More like this',
            });
        } else if (initialData?.isPersonal) {
            return i18n.t({
                id: 'ads.title.personal',
                message: 'Personalized recommendations',
            });
        } else {
            return i18n.t({
                id: 'ads.title.popular',
                message: 'Popular ads',
            });
        }
    }
    return '';
}

export function shouldShowLoadMoreButton(
    renderAllRows: boolean,
    isBottomReached: boolean,
    hasMoreFetchData: boolean,
): boolean {
    return !renderAllRows && isBottomReached && !hasMoreFetchData;
}

export function getAdProperties(item: Item) {
    const itemIdNumber: number = Number(item.itemId);
    const shippingLabel = item.shippingOption?.label;
    const p4pLabel = item.overlay?.p4p?.label;
    const easyapplyLabel = item.overlay?.easyapply?.label;

    return {
        itemIdNumber,
        shippingLabel,
        p4pLabel,
        easyapplyLabel,
    };
}
