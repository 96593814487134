import React, { useRef, useState } from 'react';
import { Item } from '../data/recommendationDataTypes';
import createHeartComponent from '@finn-no/favorite-heart-component';
import PulseTracking from '../tracking/pulseTracking';
import { useTrackInscreen } from '../tracking/trackingHooks';
import { IconShipping16 } from '@warp-ds/icons/react/shipping-16';
import { getAdProperties } from './adUtility';
import { useAppConfig } from '../utils/AppConfig';

type AdProps = {
    item: Item;
    tracking: PulseTracking;
    inscreenTrackingIsEnabled: boolean;
};

const Ad: React.FC<AdProps> = (props) => {
    const { item, tracking, inscreenTrackingIsEnabled } = props;
    const { urls, loginId, locale } = useAppConfig();
    const { cdnBaseUrl, favoriteBaseUrl } = urls;

    const domRef = useRef<HTMLDivElement>(null);

    const wcRef =
        typeof document !== 'undefined'
            ? document
                  .querySelector('recommendation-podlet-isolated')
                  ?.shadowRoot.getElementById('recommendation-podlet')
            : null;

    const Heart = createHeartComponent({
        baseUri: favoriteBaseUrl,
        userId: loginId,
        renderServerSide: true,
        modalAttach: wcRef ? wcRef : null,
    });

    const handleAdClick = (event) => {
        event.preventDefault();
        tracking.trackClickWithPulse(item.tracking?.pulse);
        setTimeout(() => {
            window.location.href = `/${item.itemId}`;
        }, 200);
    };

    const [hasBeenTracked, setHasBeenTracked] = useState(false);

    useTrackInscreen({
        tracking,
        ref: domRef,
        pulseContext: item?.tracking?.pulse,
        enabled: inscreenTrackingIsEnabled,
        hasBeenTracked,
        setHasBeenTracked,
    });

    const { itemIdNumber, shippingLabel, p4pLabel, easyapplyLabel } =
        getAdProperties(item);

    return (
        <article
            className="relative isolate recommendation-ad card card--cardShadow s-bg"
            ref={domRef}
        >
            <ImageWrapper item={item} cdnBaseUrl={cdnBaseUrl} />

            <div className="text-s s-text-subtle mx-16 mt-8">
                <span className="nonEmpty">{item.location?.combined}</span>
            </div>

            <div className="mx-16 mt-6 mb-14">
                <h2 className="h4 mb-0 break-words" id={item.itemId}>
                    <a
                        className="recommendation-ad-link s-text! hover:no-underline"
                        href={`/${item.itemId}`}
                        id={item.itemId}
                        onClick={handleAdClick}
                    >
                        <span
                            className="absolute inset-0"
                            aria-hidden="true"
                        ></span>
                        {item.heading}
                    </a>
                </h2>
            </div>

            <div className="absolute top-16 right-16">
                <Heart
                    variant="heart-on-image"
                    itemType="Ad"
                    locale={locale as 'en' | 'nb' | 'fi'}
                    itemId={itemIdNumber}
                    isMobile={false}
                    renderNumFavs={false}
                />
            </div>

            <LabelsWrapper
                shippingLabel={shippingLabel}
                easyapplyLabel={easyapplyLabel}
                p4pLabel={p4pLabel}
            />

            <div className="absolute inset-0 z-10 pointer-events-none outline-none rounded-8 border-2 border-transparent transition-all recommendation-ad-outline"></div>
        </article>
    );
};

export default Ad;

// can move to different file if needed
const ImageWrapper: React.FC<{ item: Item; cdnBaseUrl: string }> = ({
    item,
    cdnBaseUrl,
}) => {
    const image = item.image || { url: '', type: '', isAbsolute: false };

    const {
        url: imageUrl,
        type: imageType,
        isAbsolute: imageIsAbsolute,
    } = image;

    const imageClass =
        imageType === 'LOGO'
            ? 'w-full h-full object-center object-scale-down m-auto sm:h-auto'
            : 'w-full h-full object-center object-cover m-auto';

    const imageSrc =
        imageIsAbsolute || imageType === 'EXTERNAL'
            ? imageUrl
            : `${cdnBaseUrl}/${imageUrl}`;

    return (
        <>
            {/* Image wrapper */}
            <div className="aspect-square overflow-hidden rounded-8 border">
                {/* Image */}
                {imageUrl && (
                    <img
                        alt=""
                        className={imageClass}
                        sizes="(min-width: 1300px) 303px, (min-width: 1010px) 313px, (min-width: 768px) 33vw, (min-width: 480px) 50vw, 100vw"
                        src={imageSrc}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                    />
                )}

                {/* label -> only show price label if not job */}
                {item?.type !== 'JOB' && (
                    <span>
                        <div
                            className="absolute bottom-0 font-bold s-text-inverted-subtle py-10 pl-10 pr-16 custom-label-style"
                            title="Legg til favoritt"
                        >
                            {item.label}
                        </div>
                    </span>
                )}
            </div>
        </>
    );
};

const LabelsWrapper: React.FC<{
    shippingLabel?: string | null;
    easyapplyLabel?: string | null;
    p4pLabel?: string | null;
}> = ({ shippingLabel, easyapplyLabel, p4pLabel }) => {
    return (
        <>
            {shippingLabel && (
                <span className="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-warning-subtle-hover">
                    <IconShipping16 className="w-16 h-16 mr-4" />
                    {shippingLabel}
                </span>
            )}

            {easyapplyLabel && (
                <span className="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-positive-subtle-hover s-text-rgb-positive-active">
                    {easyapplyLabel}
                </span>
            )}

            {p4pLabel && (
                <span className="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-info-subtle-active">
                    {p4pLabel}
                </span>
            )}
        </>
    );
};
