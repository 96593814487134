import React, { useMemo, useRef, useState } from 'react';
import { Item, RecommendationData } from '../data/recommendationDataTypes';
import {
    useAdPositionElements,
    insertBanners,
} from '@schibsted-nmp/advertising-companion';
import { useInfiniteFetchMoreData } from '../data/recommendationDataHooks';
import PulseTracking from '../tracking/pulseTracking';
import { usePrefetchRecommendationDataQuery } from '../data/recommendationData';
import { useBottomReached } from '../tracking/bottomReachedTracking';
import { Button } from '@warp-ds/react';
import { getTitleForAds, shouldShowLoadMoreButton } from './adUtility';
import Ad from './Ad';
import { useAppConfig } from '../utils/AppConfig';

type AdsProps = {
    position: string;
    recommender: string;
    adId: number;
    numColumns: number;
    rowsToRender: number;
};

const getOikotieFragment = (iframeRef: React.RefObject<HTMLIFrameElement>) => (
    <React.Fragment key={`ad-item-5`}>
        <iframe
            ref={iframeRef}
            src={`https://recommendations.asunnot.oikotie.fi/tori-aurora-front-page.html`}
            className={`relative isolate recommendation-ad card card--cardShadow s-bg`}
            width="100%"
            height="100%"
        />
    </React.Fragment>
);

export default function Ads(props: AdsProps) {
    const { position, recommender, adId, numColumns, rowsToRender } = props;

    const { urls } = useAppConfig();
    const { apiUrl } = urls;

    const [renderAllRows, setRenderAllRows] = useState(
        rowsToRender === 0 || !rowsToRender,
    );

    const [bannerPositions, bannerElements] = useAdPositionElements({
        type: 'recommendation',
        layoutType: 'grid',
    });

    const nodeRef = useRef<HTMLDivElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

    const { recommendationData: initialData, isFetched: initialIsFetched } =
        usePrefetchRecommendationDataQuery();

    const fetchMoreUrlsPaged = renderAllRows
        ? initialData?.fetchMore
        : initialData?.fetchMore.slice(0, rowsToRender);

    const { currentFetchMoreData, isFetched, hasMoreFetchData } =
        useInfiniteFetchMoreData({
            apiUrl,
            ref: nodeRef,
            recommender,
            position,
            fetchMoreUrls: fetchMoreUrlsPaged,
        });

    const tracking = useMemo(
        () =>
            new PulseTracking({
                position,
                trackingContext: initialData?.['tracking-context'] ?? {},
            }),
        [position, initialData],
    );

    const elements = useMemo(
        () =>
            initialData?.items?.map((item: Item) => (
                <Ad
                    key={item.itemId}
                    item={item}
                    tracking={tracking}
                    inscreenTrackingIsEnabled={initialIsFetched}
                />
            )),
        [initialData, tracking, initialIsFetched],
    );

    const fetcMoreElements = useMemo(
        () =>
            currentFetchMoreData?.pages?.reduce(
                (acc, page: RecommendationData) => {
                    const items = page?.items;
                    if (items) {
                        acc.push(
                            ...items.map((item: Item) => (
                                <Ad
                                    key={item.itemId}
                                    item={item}
                                    tracking={tracking}
                                    inscreenTrackingIsEnabled={isFetched}
                                />
                            )),
                        );
                    }
                    return acc;
                },
                [] as JSX.Element[],
            ),
        [currentFetchMoreData, tracking, isFetched],
    );

    const allElements = useMemo(
        () => (elements ? [...elements, ...fetcMoreElements] : []),
        [elements, fetcMoreElements],
    );

    let adItems = allElements;

    if (position?.includes('front')) {
        // Split the elements.
        const elementsPart1 = allElements.slice(0, 4);
        const elementsPart2 = allElements.slice(4, allElements.length);

        // Insert the Oikotie ad.
        adItems = [
            ...elementsPart1,
            getOikotieFragment(iframeRef),
            ...elementsPart2,
        ];
    }

    const adsWithBanners = insertBanners({
        adItems,
        bannerPositions,
        bannerElements,
    });

    const adsTitle = useMemo(
        () =>
            initialIsFetched
                ? getTitleForAds(adId, initialData, elements?.length)
                : '',
        [initialIsFetched, adId, initialData, elements?.length],
    );

    const { bottomRef, isBottomReached } = useBottomReached({
        onBottomReachedFn: () => {
            if (!hasMoreFetchData) {
                fetch(`${apiUrl}/tracking`);
            }
        },
    });

    return (
        <div>
            <h1 className="text-m">{adsTitle}</h1>
            <div
                className={`grid f-grid grid-flow-row-dense grid-cols-2 ${
                    numColumns === 5 ? 'lg:grid-cols-5' : 'lg:grid-cols-3'
                } gap-10`}
            >
                {adsWithBanners}
                <div ref={nodeRef} />
                <div ref={bottomRef} style={{ height: '1px' }}></div>
            </div>
            <div className="text-center my-16">
                {shouldShowLoadMoreButton(
                    renderAllRows,
                    isBottomReached,
                    hasMoreFetchData,
                ) && (
                    <Button
                        primary
                        className="leading-m"
                        onClick={() => {
                            setRenderAllRows(true);
                        }}
                        ref={loadMoreButtonRef}
                    >
                        {'Se flere anbefalinger'}
                    </Button>
                )}
            </div>
        </div>
    );
}
